











































































import Vue from 'vue'

// message bus
import MessageBus from '@/message-bus'

// components
import CreateCustomerAddress from '@/components/customers/CreateCustomerAddress.vue'
import OrdersCard from '@/components/orders/OrdersCard.vue'
import { Query } from '@/components/charts/chart-settings'

// types
import { Customer, CustomerAddress, ID } from '@/types'

export default Vue.extend({
  metaInfo() {
    return {
      // @ts-expect-error
      title: this.pageTitle as string,
    }
  },
  components: {
    OrdersCard,
    CreateCustomerAddress,
  },
  props: {
    customerId: [String, Number],
  },
  data: () => ({
    dense: true,

    addAddressDialog: false,
    tab: 0,

    deletingAddressId: null as number | null,
  }),
  watch: {},
  computed: {
    customer(): Customer | undefined {
      return this.$store.getters.getCustomerById(
        parseInt(this.customerId.toString())
      )
    },
    where(): Query {
      return {
        customerId: this.customerId,
      }
    },
    pageTitle(): string {
      return this.customer?.abbr || 'Unknown Customer'
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCreateCustomerAddress(_newCustomerAddress: CustomerAddress): void {
      this.addAddressDialog = false
    },
    deleteAddress(addressId: ID) {
      this.deletingAddressId = addressId

      this.$api.customers.addresses
        .delete(this.customer?.id || -1, addressId)
        .then(z => {
          this.$store.commit('removeCustomerAddress', z)
          MessageBus.success(`Deleted: ${z.displayAddress}`)
        })
        .catch(err => {
          MessageBus.error(err)
        })
        .finally(() => {
          this.deletingAddressId = null
        })
    },
  },
})
